import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';
import './App.css';

const Home = lazy(() => import(/* webpackChunkName: 'HomePage' */ './pages/Home'));

const App = () => (
  <BrowserRouter>
    <Suspense fallback={<div />}>
      <Switch>
        <Route path="/" exact component={Home} />

        {/* 404 Page */}
        <Route component={Home} />
      </Switch>
    </Suspense>
  </BrowserRouter>
);

export default App;
